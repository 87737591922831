@import '../../../../../public/styles/theme';

.SelectList {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;

    &__area {
        padding: 1% 2% 2% 2%;
        margin-bottom: 1%;
    }

    &__container {
        display: flex;
        align-content: flex-start;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        max-height: 240px;
        border: 1px solid $color-grey-light;
    }

    &__heading {
        border-bottom: 1px dashed $color-grey;
        border-top: none;
        padding: 12px 18px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        align-items: flex-start;
        cursor: pointer;
        background: $color-white;
        color: $color-black;

        &:hover,
        &:active {
            background-color: $color-grey-lighter;
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__opinion-body {
        padding: 5px 5px 5px 19px;
        list-style-type: none;
        border-bottom: 1px dashed $color-grey-light;
        font-size: 12px;
        cursor: pointer;
    }

    &__title {
        background-color: $color-grey-lighter;
        padding-left: 15px;
        color: $color-grey-dark-dark;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-left-width: 4px;
        cursor: pointer;
    }

    &__title--positive {
        border-color: $color-green;
    }

    &__title--negative {
        border-color: $color-danger;
    }

    &__title--neutral {
        border-color: $color-info;
    }

    &__title--unknown {
        border-color: $color-grey;
    }

    &__opinion-right {
        float: right;
        padding-right: 18px;
        text-align: left;
    }

    &__opinion-right--count {
        float: right;
        padding-right: 18px;
        text-align: left;
    }

    &__opinion-toggle {
        padding-right: 15px;
        font-size: 30px;
        cursor: pointer;
    }
}
