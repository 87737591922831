$color-danger: #ff514e;
$color-info: #79bfd9;
$color-green: #5dc24a;
$color-green-secondary: #56ac46;
$color-green-third: #64c864;
$color-green-pastel: #c4e2be;
$color-grey: #8a8a8a;
$color-grey-darker: #909090;
$color-grey-light: #cccccc;
$color-grey-lighter: #ebebeb;
$color-white-dark: #efefef;
$color-white: #fff;
$color-black: #000;
$color-grey-dark-dark: #404040;
$color-modal-overlay: rgba(0, 0, 0, 0.5);

$icon-size: 18px;
$text-font-family: "Roboto";
$text-font-size: 14px;

:export {
    colorGreen: $color-green;
    colorBlue: $color-info;
    colorGreyLighter: $color-grey-lighter;
    colorGreyDarkDark: $color-grey-dark-dark;
    colorGreenSecondary: $color-green-secondary;

    fontFamily: $text-font-family;
    fontSize: $text-font-size;
}
