@import "../../public/styles/theme";
@import "../../public/styles/tools";

.Browser {
    &__logo {
        height: 70px;
        width: auto;
    }

    &__logo__wrapper {
        width: 100%;
        position: absolute;
        top: -250px;
        left: -14vw;
    }

    &__img {
        max-width: 100vw;
    }

    &__report-title {
        font-family: "Saira Condensed";
        font-size: 26px;
        margin: 0;
    }

    &__report-container {
        @extend .center-all-flex;
        color: $color-white;
        font-size: 20px;
        background-color: #252525;
        border-radius: 50%;
        width: 400px;
        height: 400px;
        text-align: center;
    }

    &__report-date {
        margin: 10px 0 15px 0;
    }

    &__container {
        @extend .center-all-flex;
        flex-direction: column;
        font-family: $text-font-family;
        background: $color-grey-dark-dark;
        height: 100vh;
        background-image: url("../../assets/img/metryczka.png");
        background-position: center;
        background-repeat: no-repeat;
    }

    &__bar--bottom {
        display: flex;
        position: absolute;
        bottom: 0;
        width: 1024px;
        justify-content: space-between;
    }

    &__client-logo {
        height: 60px;
        width: 200px;
        display: inline-block;
    }

    &__back-button {
        margin-top: 20px;
    }

    &__contact {
        position: relative;
        z-index: 2;
        color: $color-white;
    }

    &__copyright {
        position: relative;
        z-index: 2;
        color: $color-white;
    }
}

.selector {
    display: inline-block;
    width: 82%;
    padding-top: 40px;
    padding-bottom: 26px;
    color: $color-black;

    &__wrapper {
        width: 100%;
        text-align: center;
    }
}

.selectorLabel {
    font-size: 12px;
    padding-bottom: 4px;
    text-align: right;
    color: $color-green;
}
