.connectedNode {
    text-align: center;
    position: absolute;
    font-family: roboto;
    left: -40px;
    top: -20px;
    color: $color-white;
    display: block;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    align-content: center;
    justify-content: center;
    word-break: break-all;
}

.positive-node {
    background: $color-green;
}

.negative-node {
    background: $color-danger;
}

.nodeTooltip .tooltiptext {
    visibility: hidden;
    height: 24px;
    line-height: 24px;
    background-color: $color-white;
    color: $color-black;
    text-align: center;
    padding: 5px 0;
    border: 1px solid $color-black;
    border-radius: 6px;
    position: absolute;
    bottom: -40px;
    z-index: 2;
    width: 90px;
}

// TODO current using node class - why and how to fix these names
.nodeTooltip:hover .tooltiptext {
    visibility: visible;
}

.pozytywnyNode {
    background: $color-green;
}

.negatywnyNode {
    background: $color-danger;
}

.neutralnyNode {
    background: $color-info;
}

.nieznanyNode {
    background: $color-grey;
}

.NOT_FOUNDNode {
    background: $color-grey;
}

.blankNode {
    color: $color-grey-dark-dark;
    background: $color-grey-lighter;
}
