@import '../..//../../../../public/styles/theme';

.list-filter {
    font-family: $text-font-family;
    font-size: 14px;
    padding: 5px;
    padding-left: 18px;
    position: sticky;
    top: 0;
}
