.Sentiment {
    &__lengend__title {
        display: block;
        font-size: 16px;
        width: 100%;
        margin-bottom: 10px;
    }

    &__legend__chart {
        font-family: 'roboto';
        font-size: 12px;
        padding-left: 3%;
        margin: 20px 0;
        text-align: left;
        span {
            margin-right: 30px;
        }
    }
}
