@import '../../../../public/styles/_theme.scss';

.TextButton__icon {
    float: right;
    padding: 14px 0;
    cursor: pointer;
    color: $color-green;
    font-size: 16px;
}

.FiltersPanel__icon {
    padding: 0 8px;
}

.CopyButton {
    margin-right: 6px;
}

.CopiedText {
    margin-left: 10px;
    color: $color-green !important;
}
