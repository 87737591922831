@import './theme';

.DateInput_input {
    font-family: $text-font-family;
    font-size: $text-font-size;
    color: $color-black;
}

.DateInput_input__focused {
    border-bottom-color: $color-green;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right-color: $color-green;
}

.DayPicker_transitionContainer {
    font-family: $text-font-family, serif;
}

.data-picker-border {
    border-color: $color-grey-lighter;
}

.CalendarDay__default {
    @extend .data-picker-border;
}

.CalendarDay__selected_span {
    background: $color-green;
    @extend .data-picker-border;

    &:hover {
        background-color: $color-green-secondary;
        @extend .data-picker-border;
    }
}

.CalendarDay__selected {
    background-color: $color-green;
    color: $color-white;
    @extend .data-picker-border;

    &:hover {
        background-color: $color-green-secondary;
        @extend .data-picker-border;
    }
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    @extend .data-picker-border;
    background-color: $color-grey-lighter;
    color: $color-black;
}
