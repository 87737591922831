@import "../../../../public/styles/theme";

.text {
    font-family: Roboto;
    font-weight: 400;
}

.Source {
    &__list-inner {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__container-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-top: 10px;

        span:first-of-type {
            font-size: 12px;
            display: block;
            margin: 5px 0 3px 0;
        }

        span {
            font-size: 14px;
        }
    }

    &__total-wrapper {
        width: 100%;
        padding: 0 40px;
    }

    &__total-specific {
        list-style: none;
        padding: 14px 2px;
        font-size: 12px;
        color: $color-black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-child(even) {
            background-color: $color-white-dark;
        }

        &__count {
            font-weight: 700;
            font-size: 14px;
            color: $color-green;
            padding: 0px 8px;
        }
    }

    &__list {
        flex-wrap: wrap;
        padding: 0;
        font-family: Roboto;
        display: flex;
        flex-direction: row;
        padding-left: 10px;

        .element {
            font-size: 24px;
            padding-top: 7px;
            color: $color-white;
            margin: 5px;
        }
    }

    &__list__container {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        height: 80px;
        width: 60px;
        margin-right: 15px;
        cursor: pointer;
        background: $color-green-pastel;

        span {
            color: $color-grey-dark-dark;
        }

        div::before {
            color: $color-grey-darker;
        }

        &--active {
            @extend .Source__list__container;
            background: $color-green-secondary;

            span: {
                color: $color-white;
            }

            div::before {
                color: $color-white;
            }
        }
    }
}

.andOther {
    text-align: right;
    margin-right: 20px;
    font-size: 14px;
    margin-top: 20px;
    color: $color-grey;
    cursor: pointer;
}

.pagination {
    width: 100%;
    text-align: center;

    ul {
        margin-top: 40px;
    }
}
