.container {
    overflow: auto;
    height: 96%;
    width: 92%;
    padding: 0.5rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}

.main-right-container {
    flex: 1;
    flex-basis: 33%;
    height: fit-content;
    background-color: #f8f8f8;
}

.fixed-container {
    overflow: auto;
    display: flex;
    width: 100%;
    backface-visibility: hidden;
    will-change: overflow;
}
