@import "./fonts";
@import "./theme";
@import "./tools";
@import "./nodes";
@import "./react_dates_overrides";

body {
    margin: 0;
    padding: 0;
    background-color: #404040;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: roboto;
}

.container {
    display: flex;
}

.sources {
    &::before {
        font-size: $icon-size;
        font-family: FontAwesome;
        color: $color-grey-darker;
        padding-top: 7px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }
}

.FacebookSources {
    @extend .sources;
    content: "\f230";
}

.TwitterSources {
    @extend .sources;
    content: "\f099";
}

.BlogiSources {
    @extend .sources;
    content: "\f09e";
}

.ForaSources::before {
    @extend .sources;
    content: "\f27a";
}

a {
    text-decoration: none;

    &:hover,
    &:active,
    &:visited {
        text-decoration: none;
    }
}

.cloud-icon {
    font-size: 20px;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.tag-cloud-tag {
    cursor: pointer;
}

.main-link {
    text-decoration: none;
    text-transform: uppercase;
    color: $color-white;
    width: 250px;
    display: inline;
    margin-top: 10px;
    font-size: 18px;
    line-height: 20px;
    background: $color-green-secondary;
    padding: 5px 15px;
    border-radius: 15px;
    cursor: pointer;
    font-family: $text-font-family;

    &:hover {
        background: $color-green;
    }
}

.main-button {
    @extend .main-link;
    border: none;
}

.bg-white {
    background: $color-white;
}

.button {
    display: inline-block;
    color: $color-white;
    background-color: $color-green;
    font-family: $text-font-family;
    border: none;
    padding: 6px 18px;
    font-size: 14px;

    i {
        margin-left: 4px;
    }

    &:hover,
    &:active {
        background-color: $color-green-secondary;
        cursor: pointer;
    }
}

.DayPicker_weekHeader {
    top: 80px;
}

.DayPickerNavigation_button__horizontalDefault {
    top: 28px !important;
}
