@import '../../../../public/styles/theme';

.menu-list {
    padding-left: 18px;

    &__element {
        list-style: none;
        padding-bottom: 10px;

        a {
            text-decoration: none;
            color: $color-white;
            font-size: 16px;
            width: 100%;
            height: 100%;
            display: inline-block;
            padding: 6px 10px;
        }

        &--active {
            background-color: $color-green-secondary;
        }
    }
}
