@import '../../../../../public/styles/theme';

.pagination {
    margin-top: 120px;
    text-align: center;
    color: $color-white;

    li {
        padding: 6px 12px;
        border: 1px solid $color-green;
        display: inline-block;
        margin: 0 2px;
        font-size: 12px;
    }

    .custom-text {
        cursor: pointer;
        display: inline-block;
        background: transparent;
        color: $color-green;

        &:hover {
            @extend .active;
        }
    }

    .active {
        background: $color-green;
        color: $color-white;
    }

    .active a {
        @extend .active;
    }

    ul {
        display: inline-block;
    }

    .link {
        @extend .custom-text;
    }

    .disabled {
        cursor: no-drop;
        background-color: $color-grey-lighter;
        border-color: $color-grey-lighter;
        color: $color-white;

        &:hover {
            background-color: $color-grey-lighter;
        }
    }

    .break,
    .previous,
    .next {
        @extend .custom-text;
    }

    .previous.disabled {
        @extend .disabled;
    }

    .next.disabled {
        @extend .disabled;
    }
}
