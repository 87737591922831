@import '../../../../public/styles/theme';

.link {
    text-align: center;
    $size: 48px;
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-green-secondary;
    cursor: pointer;

    &:hover,
    &:active {
        background: $color-green;
    }

    i {
        color: $color-white;
        font-size: 22px;
    }
}

.disabled {
    cursor: not-allowed;
    background: $color-grey-light;

    &:hover,
    &:active {
        background: $color-grey-light;
    }
}
