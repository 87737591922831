@import '../../../../public/styles/theme';

$size: 50px; //circle size
$margin: 10px;

.circle {
    height: $size;
    width: $size;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    i {
        color: $color-white;
        font-size: 36px;
        line-height: $size;
    }
}

.panel {
    width: 100%;
    padding: 12px;
    cursor: pointer;
    margin: $margin;
    line-height: 1.7;
    padding-top: $size/2;
}

.OpinionTree {
    &__sentiment {
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        color: $color-grey;
    }

    &__sentiments-wrapper {
        display: flex;
        flex-flow: row wrap;
    }
}

.OpinionTree__panel {
    flex: 50%;
    display: flex;

    i {
        margin-right: 10px;
    }

    &__modifier-word {
        &--left {
            margin-right: auto;
        }

        &--wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        &:hover,
        &:active {
            text-decoration: underline;
        }
    }

    &__circle__wrapper {
        display: flex;
        position: relative;
        top: $size/2;
    }

    &__circle__wrapper__single {
        display: flex;
        justify-content: center;
        flex: 50%;
    }

    &__circle--positive {
        @extend .circle;
        background-color: $color-green;
    }

    &__circle--negative {
        @extend .circle;
        background-color: $color-danger;
    }

    &--positive {
        @extend .panel;
        border: 1px solid $color-green;

        i {
            color: $color-green;
        }
    }

    &--negative {
        @extend .panel;
        border: 1px solid $color-danger;

        i {
            color: $color-danger;
        }
    }
}
