@import "../../../../public/styles/theme";

.modal {
    position: absolute;
    width: 800px;
    max-width: 100%;
    z-index: 9999;
    padding: 20px;
    background: $color-white;
    border: 1px solid $color-grey-light;
    overflow-y: auto;
    opacity: 1;

    &:focus {
        outline: 0;
    }

    &__button-wrapper {
        display: flex;
        justify-content: flex-end;
        justify-content: flex-end;
        margin-top: 40px;
    }

    &__button {
        display: inline-block;
        color: $color-white;
        background-color: $color-green;
        font-family: $text-font-family;
        border: none;
        padding: 6px 18px;
        font-size: 14px;

        &:hover,
        &:active {
            background-color: $color-green-secondary;
            cursor: pointer;
        }

        i {
            margin-left: 4px;
        }

        &__copy {
            @extend .modal__button;
            margin-right: 10px;
        }
    }

    &__header {
        text-align: center;
        margin-top: 10px;
        font-family: $text-font-family;
    }

    &__text {
        text-align: justify;
        overflow-y: auto;
        max-height: 70vh;
    }

    &__body {
        display: block;
    }

    &__button {
        float: right;
    }

    &__header {
        float: center;
    }
}

.modal-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-modal-overlay;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.CopiedText {
    color: $color-green;
    text-align: right;
    margin-top: 10px;
}
