.main-container {
    width: 100%;
    font-size: 16px;
    font-family: 'roboto';
    display: grid;
    grid-template-columns: 220px 1fr;
}

.flex-container {
    display: flex;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    color: rgb(128, 128, 128);
    margin: 5rem;
}
