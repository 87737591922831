@import '../../../../../public/styles/theme';

.Balls {
    &__container {
        margin: 70px 0 90px 0;
        position: relative;
    }

    &__central-text {
        text-align: center;
        font-size: 16px;
        line-height: 10;
        cursor: default;
        white-space: nowrap;
        word-break: break-all;
    }

    &__connected-text {
        line-height: 1.3;
        cursor: default;
        word-break: break-all;
        display: flex;
        align-items: center;
    }

    &__central-node {
        display: block;
        margin: 0 auto;
        border-radius: 50%;
        width: 180px;
        height: 180px;
        position: relative;
        color: $color-grey-dark-dark;

        &--blank {
            background-color: $color-grey-lighter;
        }

        &--positive {
            background-color: $color-green;
        }

        &--negative {
            background-color: $color-danger;
        }

        &--neutral {
            background-color: $color-info;
        }
    }
}
