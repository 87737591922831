@import '../../public/styles/theme';

.Login {
    &__form-container {
        left: 50%;
        top: 50%;
        position: absolute;
        height: 300px;
        width: 300px;
        -webkit-transform: translate3d(-50%, -50%, 0);
        -moz-transform: translate3d(-50%, -50%, 0);
        transform: translate3d(-50%, -50%, 0);
    }

    &__form-elements-container {
        padding: 70px;
        display: flex;
        flex-direction: column;
    }
}
