.common {
    position: relative;
    top: 5px;
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.Legend {
    &--negative {
        &::before {
            @extend .common;
            background: #ff514e;
        }
    }

    &--positive {
        &::before {
            @extend .common;
            background: #5dc24a;
        }
    }

    &--neutral {
        &::before {
            @extend .common;
            background: #79bfd9;
        }
    }

    &--unknown {
        &::before {
            @extend .common;
            background: #8a8a8a;
        }
    }
}
