.monthAndYearSelector {
    display: flex;
    justify-content: center;

    & > div {
        font-size: 14px;
    }
}

.monthSelector {
    flex: 0 0 160px;
    margin-right: 4px;

    :global(.CalendarMonth_caption) {
        font-size: 10px;
    }
}

.yearSelector {
    flex: 0 0 86px;
}
