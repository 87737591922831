.Report {
    &__title {
        text-align: right;
        width: 98%;
        color: #808080;
        font-family: 'Saira Condensed';
        padding-right: 2%;
        margin: 10px 0;
    }

    &__content {
        display: flex;
        width: 100%;
    }
}

.main {
    padding: 20px 0 0 10px;
    background-color: #ededed;
    text-align: left;
    flex: 1;
    flex-basis: 66%;
}

.selector {
    display: flex;
    justify-content: flex-end;

    & > div {
        width: 50%;
        margin-right: 20px;

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        @media screen and (min-width: 1800px) {
            width: 30%;
        }
    }
}
