@import "../../../public/styles/theme";

$margin-left: 18px;
$margin-top: 24px;

.navbar {
    display: flex;
    flex-direction: column;
    background-color: $color-grey-dark-dark;
    height: 100vh;
    color: $color-white;

    &__image {
        max-width: 80%;
        margin-top: 24px;
        display: inline-block;
        margin-left: $margin-left;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: $margin-top * 1.5;
    margin-bottom: $margin-top;
}

.languages {
    margin-top: $margin-top;
    margin-right: $margin-left;
    margin-left: $margin-left;
}
